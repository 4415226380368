import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import {
  Typography as SunbeamTypography,
  Link as SunbeamLink,
  Divider as SunbeamDivider,
  Table as SunbeamTable,
  TableContainer as SunbeamTableContainer,
  TableRow as SunbeamTableRow,
  TableCell as SunbeamTableCell,
  TableHead as SunbeamTableHead,
  TableBody as SunbeamTableBody,
  Paper as SunbeamPaper,
} from '@achieve/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { MediaImageStandard, SuperScript } from 'components/Contentful'
import { VideoDialog } from 'components/VideoDialog'
import { get as _get, cloneDeep as _cloneDeep } from 'lodash-es'
import { BlockQuote } from 'components/BlockQuote'
import styles from './Typography.module.scss'
import { isExternal, rewriteAssetUrl } from 'utils/conversions'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { UncrawlableLink } from 'components/UncrawlableLink'

/**
 * Contentful RichText places empty paragraph after elements
 * like headings and lists if they are last item in the RichText box
 * @param {Array} content
 * @returns {Array} filtered content
 */

const UncrawlableLinkTypography = ({ node, styleOverride, variantOverride }) => {
  const { linkHref, linkText, target } = _get(node, 'data.target.fields', {})
  return (
    <UncrawlableLink
      href={linkHref}
      target={target}
      track={{
        list_name: 'Uncrawlable Link',
        click_text: `Achieve-Web | ${linkHref}`,
        click_type: 'Uncrawlable Link Click',
        track_event: 'ui_click',
        href: linkHref,
      }}
      sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx')}
      variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant')}
      data-testid={`Uncrawlable-Link-${linkText}`}
      component="button"
      className={styles['typography-hyperlink']}
    >
      {linkText}
    </UncrawlableLink>
  )
}

const removeTrailingReturnChar = (content) => {
  if (!Array.isArray(content)) {
    // Gracefully fail
    return content
  }
  if (content.length <= 1) {
    return content
  }
  const lastItem = content[content.length - 1]
  if (
    typeof lastItem.nodeType === 'string' &&
    lastItem.nodeType.match(/paragraph/i) &&
    lastItem.content.length === 1 &&
    lastItem.content[0].nodeType === 'text' &&
    lastItem.content[0].value === ''
  ) {
    return content.slice(0, -1)
  }
  return content
}

/**
 * Always returns textContent with content array as a prop in the shape
 * @param {Object} content
 * @returns {Object} textContent: {content: []}
 */
const handleContentToMapper = (content = {}) => {
  let textContent = {}
  if (Object.hasOwnProperty.call(content, 'fields')) {
    textContent = content.fields.textContent
  } else if (Object.hasOwnProperty.call(content, 'nodeType')) {
    textContent = content
  }
  textContent.content = removeTrailingReturnChar(textContent.content)

  return { textContent }
}

const RICH_TEXT = {
  [BLOCKS.PARAGRAPH]: { variant: 'displayS10', component: 'p' },
  [BLOCKS.HEADING_1]: { variant: 'displayL30', component: 'h1' },
  [BLOCKS.HEADING_2]: { variant: 'displayL20', component: 'h2' },
  [BLOCKS.HEADING_3]: { variant: 'displayL10', component: 'h3' },
  [BLOCKS.HEADING_4]: { variant: 'displayM30', component: 'h4' },
  [BLOCKS.HEADING_5]: { variant: 'displayM20', component: 'h5' },
  [BLOCKS.HEADING_6]: { variant: 'displayM10', component: 'h6' },
  [BLOCKS.OL_LIST]: { component: 'ol' },
  [BLOCKS.UL_LIST]: { component: 'ul' },
  [BLOCKS.LIST_ITEM]: { component: 'li' }, //sx properties apply to single child element
  [BLOCKS.HR]: {},
  [BLOCKS.EMBEDDED_ASSET]: {},
  [BLOCKS.TABLE]: { variant: 'displayS10', component: 'table' },
  [BLOCKS.TABLE_ROW]: { variant: 'displayS10', component: 'tr' },
  [BLOCKS.TABLE_CELL]: { variant: 'displayS10', component: 'td' },
  [BLOCKS.TABLE_HEADER_CELL]: { variant: 'displayS10', component: 'th' },
  [INLINES.HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  [INLINES.ASSET_HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  // strong is semantic bold
  [MARKS.BOLD]: { variant: 'displayS10', component: 'strong' },
  // Currently unimplemented in Sunbeam
  //[MARKS.CODE]: {component: 'code'},
  //[BLOCKS.QUOTE]:{},
}
/**
 * Returns the overridden value for a given rich text enum, or the default value from the RICH_TEXT
 * object.
 *
 * @param {string} richTextEnum - The rich text enum to check for an override.
 * @param {object} overrideObject - The object containing the overrides for the rich text enums.
 * @param {string} key - The key to retrieve the value from the rich text enum or override object.
 * @param {object} richText - The RICH_TEXT object containing the default values for the rich text
 * enums.
 * @returns {string} - The overridden value for the rich text enum, or the default value from the
 * RICH_TEXT object.
 */
const selectOverride = (richTextEnum, overrideObject, key, richText = RICH_TEXT) => {
  return typeof overrideObject === 'object' &&
    Object.hasOwnProperty.call(overrideObject, richTextEnum)
    ? overrideObject[richTextEnum]
    : richText[richTextEnum][key]
}

const parseRichTextLineBreak = (children) => {
  if (Array.isArray(children) && children.length === 1 && children[0] === '') {
    return ['\u00A0']
  }
  // Remove line separator characters from Contentful RichText
  return children.map((child) =>
    typeof child === 'string' ? child.replaceAll(/[\u2028\u2029]/g, '') : child
  )
}

const TypographyStringType = ({
  styleOverride,
  variantOverride,
  typographyContent,
  richText = RICH_TEXT,
  props,
}) => {
  return (
    <SunbeamTypography
      sx={selectOverride(BLOCKS.PARAGRAPH, styleOverride, 'sx')}
      variant={selectOverride(BLOCKS.PARAGRAPH, variantOverride, 'variant')}
      component={richText[BLOCKS.PARAGRAPH].component}
      {...props}
    >
      {typographyContent}
    </SunbeamTypography>
  )
}

/**
 * Create renderNode options for BLOCKS that return similar/identical elements (headers and lists)
 * @param {Object} node      //Is provided by documentToReactComponents
 * @param {Object} children  //Is provided by documentToReactComponents
 * @param {String} blockType      //BLOCKTYPE allowed by documentToReactComponents
 * @param {Boolean} variantToChild //true if variant override should be applied to child component
 * @param {Boolean} styleToChild   //true if style override should be applied to child component
 * @returns {JSX} <JSX element to be displayed >
 */
function createRenderNodeDefault({
  children,
  blockType,
  variantToChild = false,
  styleToChild = false,
  variantOverride,
  styleOverride,
  className,
  richText = RICH_TEXT,
  propsComponent,
}) {
  //Logic to pass variant and style to children (uses: ie. BLOCK.LIST_ITEM which have nested <p>)
  let childWithParentProps = children
  if (variantToChild || styleToChild) {
    childWithParentProps = _cloneDeep(children)
    if (variantToChild) {
      //Pass block variant to child
      childWithParentProps.forEach((child) => {
        if (Object.hasOwnProperty.call(child, 'props'))
          child.props.variant = selectOverride(
            BLOCKS[blockType],
            variantOverride,
            'variant',
            richText
          )
      })
    }
    if (styleToChild) {
      //Pass block style to child
      childWithParentProps.forEach((child) => {
        if (Object.hasOwnProperty.call(child, 'props'))
          child.props.sx = selectOverride(BLOCKS[blockType], styleOverride, 'sx', richText)
      })
    }
  }
  const classNames = [className, propsComponent.className].join(' ')

  return (
    <SunbeamTypography
      sx={selectOverride(BLOCKS[blockType], styleOverride, 'sx', richText)}
      variant={selectOverride(BLOCKS[blockType], variantOverride, 'variant', richText)}
      component={richText[BLOCKS[blockType]].component}
      {...propsComponent}
      className={classNames}
    >
      {parseRichTextLineBreak(childWithParentProps)}
    </SunbeamTypography>
  )
}

function optionsDefault({
  createRenderNode,
  props,
  styleOverride,
  variantOverride,
  richText = RICH_TEXT,
  stylesNodes = styles,
}) {
  return {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong {...props}>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em {...props}>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => createRenderNode(node, children, 'PARAGRAPH'),
      [BLOCKS.HEADING_1]: (node, children) => createRenderNode(node, children, 'HEADING_1'),
      [BLOCKS.HEADING_2]: (node, children) => createRenderNode(node, children, 'HEADING_2'),
      [BLOCKS.HEADING_3]: (node, children) => createRenderNode(node, children, 'HEADING_3'),
      [BLOCKS.HEADING_4]: (node, children) => createRenderNode(node, children, 'HEADING_4'),
      [BLOCKS.HEADING_5]: (node, children) => createRenderNode(node, children, 'HEADING_5'),
      [BLOCKS.HEADING_6]: (node, children) => createRenderNode(node, children, 'HEADING_6'),
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol className={stylesNodes['typography-ol']}>{children}</ol>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className={stylesNodes['typography-ul']}>{children}</ul>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className={stylesNodes['typography-li']}>{children}</li>
      },
      [BLOCKS.HR]: () => {
        return (
          <SunbeamDivider
            className={stylesNodes['typography-hr']}
            sx={selectOverride(BLOCKS.HR, styleOverride, 'sx', richText)}
            {...props}
          />
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageWidth = _get(node, 'data.target.fields.file.details.image.width')
        const imageHeight = _get(node, 'data.target.fields.file.details.image.height')

        return (
          <>
            <MediaImageStandard
              content={node?.data.target}
              width={imageWidth}
              height={imageHeight}
              {...props}
            />
            <p></p>
          </>
        )
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div>
            <SunbeamTableContainer
              component={SunbeamPaper}
              className={`${stylesNodes['typography-table']} ${
                node?.content[0]?.content?.length < 4 && stylesNodes['typography-table-full']
              }`}
            >
              <SunbeamTable
                size="small"
                sx={selectOverride(BLOCKS.TABLE, styleOverride, 'sx', richText)}
                variant={selectOverride(BLOCKS.TABLE, variantOverride, 'variant', richText)}
                {...props}
              >
                {children}
              </SunbeamTable>
            </SunbeamTableContainer>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        const childrenNodeTypes = node?.content?.map((c) => c?.nodeType) ?? []
        if (childrenNodeTypes.includes(BLOCKS.TABLE_HEADER_CELL)) {
          return (
            <SunbeamTableHead>
              <SunbeamTableRow>{children}</SunbeamTableRow>
            </SunbeamTableHead>
          )
        }
        return (
          <SunbeamTableBody>
            <SunbeamTableRow>{children}</SunbeamTableRow>
          </SunbeamTableBody>
        )
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return (
          <SunbeamTableCell className={stylesNodes['typography-cell']}>{children}</SunbeamTableCell>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return (
          <SunbeamTableCell className={stylesNodes['typography-cell']}>{children}</SunbeamTableCell>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <HYPERLINK node={node} props={props} styleOverride={styleOverride} variantOverride>
            {children}
          </HYPERLINK>
        )
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const linkId = node?.content[0]?.value
        const url = rewriteAssetUrl(node?.data?.target?.fields?.file.url)
        return (
          <SunbeamLink
            target="_blank"
            sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', richText)}
            variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant', richText)}
            data-testid={`Link-${url}-${linkId}`}
            href={rewriteAssetUrl(url)}
            aria-label={`${children} - link opens in a new tab`}
            {...props}
            component={richText[INLINES.ASSET_HYPERLINK].component}
          >
            {children}
          </SunbeamLink>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // TODO: Handle other types of inline entries. Currently,
        //       node is a data object with no content. Rendering in
        //       an html tag does not work, invalid React child
        const { plainText, styledText, variation, identifier, videoPlayer } = _get(
          node,
          'data.target.fields',
          {}
        )
        const { contentType } = _get(node, 'data.target.sys', {})
        if (styledText === 'superscript') {
          return <SuperScript text={plainText} variation={variation} {...props} />
        }
        if (styledText === 'scrollTo') {
          return <span data-scrolto={identifier}></span>
        }
        if (styledText === 'scrollFrom') {
          return (
            <SunbeamLink
              sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', richText)}
              variant={selectOverride(
                INLINES.ASSET_HYPERLINK,
                variantOverride,
                'variant',
                richText
              )}
              data-testid={`Scroll-to-${plainText}`}
              onClick={() => {
                const el = document.querySelector(`[data-scrolto="${identifier}"]`)
                if (el) {
                  // Adding -70px to fix element padding
                  window.scrollTo({
                    top: el.offsetTop - 70,
                    behavior: 'smooth',
                  })
                }
              }}
              aria-label={`Scroll to ${plainText}`}
              {...props}
              component={richText[INLINES.ASSET_HYPERLINK].component}
            >
              {plainText}
            </SunbeamLink>
          )
        }
        if (videoPlayer) {
          const { target } = _get(node, 'data', {})
          return <VideoDialog content={target} />
        }

        if (contentType?.sys?.id === 'uncrawlableLink') {
          return (
            <UncrawlableLinkTypography
              node={node}
              styleOverride={styleOverride}
              variantOverride={variantOverride}
            />
          )
        }

        return null
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div>
            <SunbeamTableContainer
              component={SunbeamPaper}
              className={`${stylesNodes['typography-table']} ${
                node?.content[0]?.content?.length < 4 && stylesNodes['typography-table-full']
              }`}
            >
              <SunbeamTable
                size="small"
                sx={selectOverride(BLOCKS.TABLE, styleOverride, 'sx', richText)}
                variant={selectOverride(BLOCKS.TABLE, variantOverride, 'variant', richText)}
                {...props}
              >
                {children}
              </SunbeamTable>
            </SunbeamTableContainer>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        const childrenNodeTypes = node?.content?.map((c) => c?.nodeType) ?? []
        if (childrenNodeTypes.includes(BLOCKS.TABLE_HEADER_CELL)) {
          return (
            <SunbeamTableHead>
              <SunbeamTableRow>{children}</SunbeamTableRow>
            </SunbeamTableHead>
          )
        }
        return (
          <SunbeamTableBody>
            <SunbeamTableRow>{children}</SunbeamTableRow>
          </SunbeamTableBody>
        )
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return (
          <SunbeamTableCell className={stylesNodes['typography-cell']}>{children}</SunbeamTableCell>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return (
          <SunbeamTableCell className={stylesNodes['typography-cell']}>{children}</SunbeamTableCell>
        )
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return <BlockQuote text={node.content}>{children}</BlockQuote>
      },
    },

    renderText: (text) => text,
  }
}

/**
 * Render Contentful Typography components from Contentful Rich Text
 * To build the variantOverride object you will need to import import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
 * @param {Object} content
 * @param {Object} variantOverride object of rich-text-types as keys and Sunbeam Variants as the value
 * @param {Object} styleOverride object of rich-text-types as keys and a MUI "sx" object as the value
 * @returns
 */
function Typography({ content: typographyContent = '', variantOverride, styleOverride, ...props }) {
  if (typeof typographyContent === 'string') {
    return TypographyStringType({ styleOverride, variantOverride, typographyContent, props })
  }
  // otherwise must be an object
  const { textContent } = handleContentToMapper(typographyContent)

  const createRenderNode = (
    node,
    children,
    blockType,
    className = 'typography-default',
    variantToChild = false,
    styleToChild = false
  ) => {
    const dataTestId = _get(node, 'content[0].value')
      ? `${_get(node, 'content[0].value')}`.slice(0, 50)
      : 'no-id-needed'
    const propsComponent = {
      ['data-testid']: dataTestId,
      ...props,
    }

    return createRenderNodeDefault({
      children,
      blockType,
      variantToChild,
      styleToChild,
      variantOverride,
      styleOverride,
      className,
      propsComponent,
    })
  }

  const options = optionsDefault({ createRenderNode, props, styleOverride, variantOverride })

  return documentToReactComponents(textContent, options)
}

function HYPERLINK({ node, children, props, styleOverride, variantOverride }) {
  const currentURL = useCurrentUrl()
  const linkId = node?.content[0].value
  const url = node?.data.uri
  return (
    <AchieveLink
      noLink
      track={{
        list_name: 'Inline Link',
        click_id: linkId,
        click_text: `Achieve-Web | ${linkId}`,
        click_position: '0',
        click_type: 'Button Click',
        nav_link_section: 'CTA Card - In-Content',
        track_event: 'page_nav_click',
      }}
      sx={selectOverride(INLINES.HYPERLINK, styleOverride, 'sx')}
      variant={selectOverride(INLINES.HYPERLINK, variantOverride, 'variant')}
      data-testid={`Link-${url}-${linkId}`}
      href={url}
      target={isExternal(url, currentURL) ? '_blank' : '_self'}
      {...props}
      component={RICH_TEXT[INLINES.HYPERLINK].component}
    >
      {children[0] || children}
    </AchieveLink>
  )
}

export default Typography
export {
  Typography,
  removeTrailingReturnChar,
  RICH_TEXT,
  handleContentToMapper,
  selectOverride,
  HYPERLINK,
  parseRichTextLineBreak,
  TypographyStringType,
  createRenderNodeDefault,
  optionsDefault,
  UncrawlableLinkTypography,
}
