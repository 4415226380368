import { Image } from 'components/Image'
import { get as _get } from 'lodash-es'
import styles from './MediaImageStandard.module.scss'
import { rewriteAssetUrl } from 'utils/conversions/urls'
import classNames from 'classnames'

/**
 * Renders a media image component with content from Contentful.
 *
 * USAGE:
 *  Option 1 & 2: layout == 'fill' -> Image will scale down to fit containers dimensions but not more than original image dimensions
 *  Option 1: objectFit == 'cover' -> image fills container cropped to container aspect ratio
 *  Option 2: objectFit == 'contain' -> image fits fully inside container scales to first axis to hit container boundary
 *
 *  Option 3: Keep image aspect ratio and allow image to be any size to fit responsive container.
 *
 * All options:
 * 		- Style border radius on MediaImageStandard element
 * 		- Padding/Margin must be set to an additional container around the MediaImageStandard.
 *
 * @param {object} props - Component properties
 * @param {object} props.content - Content data. Required, the image content object from Contentful
 * @param {string} [props.alt] - Alternative text for the image
 * @param {number|string} [props.width] - Image width. Use with height if layout='fill' and want fixed size
 * @param {number|string} [props.height] - Image height.  Use if layout='fill' and want fixed image height
 * @param {'responsive'|'fill'} [props.layout='responsive'] - Image layout
 * @param {'cover'|'contain'|'fill'|'none'|'scale-down'} [props.objectFit='cover'] - Image fit. Optional (only affects layout='fill')
 * @param {'left'|'center'|'right'|'top'|'center'|'bottom'} [props.objectPosition='center'] - Image position.  Optional (only affects layout='fill')
 * @param {object} [restProps] - Other properties
 */
export function MediaImageStandard({
  content,
  alt,
  width = undefined,
  height = undefined,
  layout = 'responsive',
  objectFit = undefined,
  objectPosition = undefined,
  className,
  ...restProps
}) {
  const { file, title } = content?.fields || {}
  const assetUrl = rewriteAssetUrl(file?.url)

  if (!assetUrl && !restProps?.src) return null

  //Get original image dimensions if width or height not provided as argument
  if (layout === 'responsive') {
    height = _get(file, 'details.image.height')
    width = _get(file, 'details.image.width')
  }

  const extraClassName = styles[`image-${layout}`]
  const objectFitClassName = objectFit ? styles[`object-fit-${objectFit}`] : null
  const objectPositionClassName = objectPosition
    ? styles[`object-position-${objectPosition.replace(/\s/g, '-')}`]
    : null

  //Option 1 & 2:
  if (layout === 'fill') {
    return (
      <div
        className={
          objectFit === 'contain'
            ? styles['image-container-fit-contain']
            : styles['image-container-fit-cover']
        }
        style={{
          width: width,
          height: height,
        }}
        data-testid="media-image-container"
      >
        <Image
          src={assetUrl}
          alt={alt || title}
          height={height || _get(file, 'details.image.height')}
          width={width || _get(file, 'details.image.width')}
          className={classNames(
            objectFitClassName,
            objectPositionClassName,
            className,
            extraClassName
          )}
          {...restProps}
        />
      </div>
    )
  }

  //Option 3:
  return (
    <Image
      src={assetUrl}
      alt={alt || title}
      height={height || _get(file, 'details.image.height')}
      width={width || _get(file, 'details.image.width')}
      className={classNames(objectFitClassName, objectPositionClassName, className, extraClassName)}
      {...restProps}
    />
  )
}
