import React from 'react'
import classNames from 'classnames'
import { Button } from '@achieve/sunbeam'
import { Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import styles from './LinkButton.module.scss'

// Thes variants are tightly coupled to Button from `@achieve/sunbeam` and ideally they would be
// exposed with typescript. They should be kept in sync with variants for `Button` component from
// Sunbeam/MUI if those every get updated or additional variants are created for `Button`.
const LINK_BUTTON_VARIANTS = {
  TEXT: 'text',
  OUTLINED: 'outlined',
  CONTAINED: 'contained',
}

const ButtonWithRef = React.forwardRef(
  (
    {
      onClick,
      passThroughOnClick = () => {},
      label,
      variant = LINK_BUTTON_VARIANTS.CONTAINED,
      color = 'primary',
      typographicOptions = {},
      className = '',
      ...restProps
    },
    // eslint complains that ref is unused, but React also complains that you
    // have to include a ref for a forwardRef render function
    ref // eslint-disable-line no-unused-vars
  ) => {
    const localClickHandler = (event) => {
      if (typeof onClick === 'function') {
        onClick(event)
      }
      passThroughOnClick(event)
    }

    return (
      <Button
        color={color}
        variant={variant}
        data-variant={variant}
        className={classNames(styles['base-styles'], className)}
        onClick={(event) => {
          localClickHandler(event)
        }}
        {...restProps}
      >
        <Typography content={label} {...typographicOptions} />
      </Button>
    )
  }
)

export const LinkButton = ({
  content,
  onClick,
  variant,
  color,
  typographicOptions,
  track,
  disabled = false,
  ...restProps
}) => {
  if (!content?.fields && !content) return null

  const { linkText, linkHref } = content?.fields ? content.fields : content

  return (
    <AchieveLink
      href={disabled ? 'javascript:void(0)' : linkHref}
      passHref
      track={track}
      noLink
      withNextLink
      disabled={disabled}
    >
      <ButtonWithRef
        label={linkText}
        passThroughOnClick={onClick}
        variant={variant}
        color={color}
        typographicOptions={typographicOptions}
        disabled={disabled}
        {...restProps}
      />
    </AchieveLink>
  )
}

// Expose button variants to consumers
LinkButton.VARIANTS = LINK_BUTTON_VARIANTS

ButtonWithRef.displayName = 'LinkButton'
